.brand-icon-title {
  display: flex;
  align-items: center;
}

.brand-icon-title img {
  background: #f9f9f9;
}

.brand-icon-title .brand-icon {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  background-color: #eeeeee;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #00000026;
  font-weight: bolder;
  background-size: cover;
}

.rating {
  text-align: center;
  font-size: 35px;
  display: flex;
  align-content: center;
  flex-direction: column;
  color: #fdbe4d;
  font-weight: 500;
}

.brand-icon-title h3 {
  display: flex;
  color: #000;
  margin: 0;
  margin-left: 15px;
}

.white-data-section {
  background: #fff;
  padding: 15px 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
}

.white-data-section h2 {
  font-size: 14px;
  margin-left: 20px;
  font-weight: 500;
  position: relative;
}

.white-data-section p {
  font-size: 13px;
  line-height: 18px;
}

.white-data-section h2::after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #52b797;
  position: absolute;
  top: 5px;
  left: -18px;
  border-radius: 5px;
}

.white-data-section h3 {
  font-size: 14px;
  font-weight: 500;
}

.bottom.info {
  margin: 20px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 15px;
  padding: 20px;
}

.bottom.info h2 {
  margin: 0;
}

.bottom.info h2:after {
  content: "";
  display: none;
}

.brand-video iframe {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  border: 0;
}

.brand-contact-data {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 15px;
  padding: 10px 15px;
}

.brand-contact-icon {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.brand-contact-text {
  margin-left: 20px;
}

.brand-contact-icon svg {
  background: #1c1c1c;
  border-radius: 6px;
  color: #fff;
  padding: 7px;
}

.brand-contact-copy {
  display: flex;
  align-items: center;
}
