.light.flex.MuiBox-root {
  display: flex;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
}

.light.flex.MuiBox-root button.MuiButton-root.MuiButton-text.rounded {
  width: 45%;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.back-button {
  opacity: 0.4;
  /* Text */
  border: 1px solid #1c1c1c;
  box-sizing: border-box;
  border-radius: 50px;
  background: unset;
  color: #1c1c1c;
}

.row.item.full.select .MuiFormControl-root {
  width: 100%;
}

.row.item.full.select > div {
  margin-bottom: 15px;
}

.flex-reg-data {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}

.flex-reg-data .row.item {
  width: 47%;
}
