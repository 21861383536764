.MuiPaper-root.MuiPaper-elevation.MuiDialog-paper.MuiDialog-paperScrollPaper {
  border-radius: 15px;
}

.filters-container {
  width: 100%;
}

.modal-window .modal-window-info .filters-items {
  display: flex;
  align-content: space-between;
  border: 1px solid #838383;
  box-sizing: border-box;
  border-radius: 50px;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  margin: 10px 0;
  color: #838383;
  width: 100%;
}

.filters-items.active {
  border: 1px solid #52b797;
  background: rgba(82, 183, 151, 0.15);
}

.modal-window-info h2 {
  font-size: 15px;
  font-weight: 500;
}

button.MuiButton-root.MuiButtonBase-root.modal-buttons.yes.orange {
  background: linear-gradient(90deg, #ffad65 -10.67%, #ff7636 121.34%);
  border: 0;
}

.modal-window-info {
  text-align: center;
  background: #bababa5e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 35px;
}

.info-text {
  margin-top: 40px;
}

.info-text span {
  color: #52b797;
  font-weight: 700;
}

.modal-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

button.MuiButton-root.MuiButtonBase-root.modal-buttons.no {
  border: 1px solid #1c1c1c3d;
  box-sizing: border-box;
  border-radius: 50px;
  text-transform: capitalize;
  width: 45%;
  color: #1c1c1c87;
}

button.MuiButton-root.MuiButtonBase-root.modal-buttons.yes {
  border: 1px solid #1c1c1c3d;
  box-sizing: border-box;
  border-radius: 50px;
  text-transform: capitalize;
  background: #fd6f6f;
  width: 45%;
  color: #fff;
}

.modal-buttons-container.congrats {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.present {
  position: absolute;
  width: 100%;
  height: inherit;
  background-position: center;
  z-index: 10;
  background-repeat: no-repeat;
}

.modal-window.congrats {
  position: relative;
}

.congragulations-info {
  position: relative;
  height: 240px;
}

.background-present {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: inherit;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.MuiDialogContent-root.green-modal {
  background: linear-gradient(0deg, #52b797, #a7dd9a);
  border-radius: 15px 15px 0px 15px;
}

.modal-buttons-container.congrats h2 {
  color: #fff;
  font-weight: 400;
}

.modal-buttons-container.congrats .congrats-data h2 {
  font-size: 16px;
  background-color: #ff7636;
  margin: 0;
  padding: 10px;
  border-radius: 15px 15px 0px 0px;
}

.modal-buttons-container.congrats .congrats-data {
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
}

.modal-buttons-container.congrats .congrats-data h3 {
  color: #ff7636;
}

.modal-buttons-container.congrats button.MuiButton-root {
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  border: 1px solid #fff;
  width: 100%;
  margin: 15px;
  border-radius: 15px;
  padding: 10px;
}

.modal-buttons-container.congrats .go-back-home {
  margin: 10px;
  color: #fff;
  opacity: 0.5;
}

.sun-rays {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: -webkit-fill-available;
  background-position: center;
  background-position-y: 67%;
  background-size: 500%;
  background-repeat: no-repeat;
  opacity: 0.2;
}

.modal-window-info.cookies {
  background: #ffc52729;
}

.modal-window-info.cookies h2 {
  font-size: 20px;
}

.modal-window-info.cookies h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
