.users-name {
  margin: 0px 10px;
}

.users-name .first_name {
  font-size: 14px;
  color: #52b797;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.worker
  svg {
  display: none;
}

.users-name .last_name {
  font-size: 11px;
  font-weight: 300;
  color: #00000080;
  margin-top: 2px;
}

.brands-list .brand-icon-title .brand-icon.small {
  width: 20px;
  height: 20px;
}

.pay-day {
  display: flex;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  background: #fefefe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 20px 0px;
}

.pay-day-icon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}

.pay-day h3 {
  font-size: 13px;
  color: #8d8d8d;
  margin: 0;
}

.pay-day-icon span {
  margin-right: 10px;
}

.brands-list .buttons {
  display: flex;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
}

.MuiFormControl-root.select-status .MuiOutlinedInput-root.MuiInputBase-root {
  color: #fff;
  border-radius: 10px;
}

.MuiFormControl-root.select-status
  .MuiOutlinedInput-root.MuiInputBase-root
  .MuiSelect-select.MuiSelect-outlined {
  padding: 13px;
  font-size: 12px;
}

.MuiFormControl-root.select-status
  .MuiOutlinedInput-root.MuiInputBase-root
  svg {
  color: #fff;
  font-size: 30px;
  border-left: 1px solid #fff;
}

.MuiFormControl-root.select-status
  .MuiOutlinedInput-root.MuiInputBase-root
  svg.MuiSelect-iconOpen {
  border-left: unset;
  border-right: 1px solid #fff;
}

button.MuiButton-root.MuiButton-text.confirm-payment {
  background: #ff6161;
  border-radius: 10px;
  padding: 13px;
  color: #fff;
  text-transform: capitalize;
  font-size: 12px;
  width: 48%;
}

.MuiFormControl-root.select-status fieldset.MuiOutlinedInput-notchedOutline {
  border: 0;
}

.MuiFormControl-root.select-status.Lead
  .MuiOutlinedInput-root.MuiInputBase-root {
  background-color: #f9ac39;
}

.MuiFormControl-root.select-status.Customer
  .MuiOutlinedInput-root.MuiInputBase-root {
  background-color: #6b80cb;
}

.MuiFormControl-root.select-status.Paid
  .MuiOutlinedInput-root.MuiInputBase-root {
  background-color: #52b797;
}

.MuiFormControl-root.select-status.Archive
  .MuiOutlinedInput-root.MuiInputBase-root {
  background-color: #575757;
}

@media screen and (max-width: 340px) {
  .brands-list .buttons {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .brands-list .buttons > * {
    width: 100%;
    margin-bottom: 15px;
  }

  .brands-list .buttons button {
    width: 100%;
  }

  button.MuiButton-root.MuiButton-text.confirm-payment {
    width: 100%;
  }
}
