button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.rounded.center.text-black {
  color: #9c9c9c;
}

.PhoneInput {
  border-radius: 15px;
  background-color: #fbfbfb;
  border: 1px solid #c1c1c1;
}

input.PhoneInputInput {
  padding: 16.5px 14px 16.5px 0px;
  border-radius: 15px;
  border: 0;
  outline: 0;
  outline-offset: 0;
  font-size: 16px;
}

.PhoneInputCountry {
  margin-left: 15px;
  margin-right: 10px;
}

.PhoneInputCountrySelectArrow {
  margin-left: 10px;
}

button.google span {
  font-weight: 300 !important;
}

.select-auth-method h3.phone-check {
  font-size: 13px;
  color: #2f2f2f;
  font-weight: 400;
}
button#appleid {
  opacity: 0;
  pointer-events: none;
}

.MuiFormControl-root.MuiTextField-root.center input {
  text-align: center;
  font-size: 18px;
}

.welcome-text.center {
  text-align: center;
  margin-top: 50px;
}

.select-auth-method > button.MuiButtonBase-root {
  border-radius: 50px;
  width: 100%;
  margin-bottom: 20px;
}

.auth-item {
  text-align: center;
  display: flex;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: rgb(196 196 196 / 11%) !important;
  border: 1px solid #1c1c1c29 !important;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 10px !important;
  font-size: 14px !important;
  transition: all 0.5s;
}
button.google > div {
  display: none;
}

button.google {
  background-color: unset !important;
  font-size: unset !important;
  border-radius: unset !important;
  border: unset !important;
  box-shadow: unset !important;
  display: block !important;
  font-weight: unset !important;
  color: unset !important;
  width: 100%;
}

.proceed-auth-button {
  color: #fff;
  background: linear-gradient(90deg, #ffad65 -10.67%, #ff7636 121.34%);
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.auth-icon {
  width: 35px;
  height: 35px;
  background-size: cover;
  background-color: #53b797;
  border-radius: 10px;
  box-shadow: 0 1px 3px 1px #00000024;
}

.MuiButtonBase-root.proceed-auth-button {
  background: linear-gradient(90deg, #ffad65 -10.67%, #ff7636 121.34%);
  color: #fff;
}
