body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error.PhoneInput {
  border-color: red;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p.MuiTypography-root.MuiTypography-body1.error {
  color: red;
  font-size: 14px;
}

.MuiFormControl-root.select-status.disabled
  .MuiOutlinedInput-root.MuiInputBase-root {
  pointer-events: none;
}

.no-img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #eee;
  border-radius: 50px;
  box-shadow: 0 0 0 7px #eeeeee9c;
  color: #d0d0d0;
  font-size: 19px;
  font-weight: 700;
}

.info-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
