.swipe-accept {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.swipeable-list-item.not_avaliable {
  opacity: 0.5;
  pointer-events: none;
}

.swipe-accept .brand-icon {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #f9f9f9;
  padding: 10px;
  background-position: center;
  background-size: 44px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-size: cover;
}

.swipe-accept .left {
  display: flex;
  align-items: center;
}

.swipe-accept .payment-info h3 {
  margin: 8px 5px;
  font-size: 16px;
}

.swipe-accept .payment-info h4 {
  font-size: 14px;
  font-weight: 300;
  margin: 8px 5px;
}

.swipe-accept .left .payment-info {
  margin-left: 13px;
}

.swipe-accept .right .payment-info {
  text-align: right;
}

.swipe-accept .right .payment-info h4 {
  font-weight: 600;
}

.swipeable-list-item:last-child .swipe-accept {
  border-bottom: unset;
}
.accept-payment {
  display: flex;
  background: #52b797;
  border-radius: 0px 10px 10px 0px;
  flex-direction: column;
  align-content: center;
  justify-content: center !important;
  align-items: center;
  font-size: 11px;
  color: #fff;
  padding: 20px;
}

.accept-payment > div {
  margin-top: 5px;
}
