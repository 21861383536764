.shake {
  animation: shake-animation 2.72s ease infinite;
  transform-origin: 50% 50%;
}
.element {
  margin: 0 auto;
  width: 150px;
  height: 150px;
  background: red;
}
@keyframes shake-animation {
   0% { transform:translate(0,0) }
  10.78571% { transform:translate(5px,0) }
  13.57143% { transform:translate(0,0) }
  16.35714% { transform:translate(5px,0) }
  19.14286% { transform:translate(0,0) }
  21.92857% { transform:translate(5px,0) }
  24.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
}
