.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-info .user-name-role {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: nowrap;
}

.user-info .user-name-role h5 {
  margin: 0px;
  font-weight: 300;
  font-size: 10px;
  color: #939393;
}

.user-info .user-name-role h4 {
  color: #52b797;
}

.user-info .user-image {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  margin-right: 15px;
  box-shadow: 0 1px 3px 0 #00000069;
  border-radius: 50px;
  color: #acacac;
}

.user-data .bottom {
  background: #fefefe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}

.bottom {
}

.user-data .bottom .bottom-container {
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  margin: 20px 0;
  color: #9d9d9d;
}

.ammount-to-pay {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  padding: 15px 0;
}

.ammount-to-pay div {
  width: 49%;
  text-align: center;
  padding: 20px;
}

.ammount-to-pay div.total {
  background: rgba(82, 183, 151, 0.15);
  /* green */
  border: 1px solid #52b797;
  box-sizing: border-box;
  border-radius: 15px;
  color: #52b797;
}

.ammount-to-pay div.need-to {
  background: rgba(244, 121, 121, 0.15);
  border: 1px solid #f47979;
  box-sizing: border-box;
  border-radius: 15px;
  color: #f47979;
}

.ammount-to-pay div h5 {
  margin: 0;
  font-size: 18px;
}

.ammount-to-pay div h4 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 15px;
}

.user-card.birthday {
  background: linear-gradient(102.81deg, #52b797 8.11%, #eeff87 98.41%);
}

.user-card {
  position: relative;
  margin-top: 15px;
  padding: 40px;
  background: #1c1c1c;
  border-radius: 6px;
  background-position: right;
  background-repeat: no-repeat;
  color: #fff;
  font-weight: 600;
  background-size: contain;
  margin-bottom: 50px;
}

.user-card .back-image {
  position: absolute;
  width: -webkit-fill-available;
  padding: 40px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: right;
}
