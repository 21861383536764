html,
body {
  overflow-x: hidden;
}

.code_confirm .code input::-webkit-outer-spin-button,
.code_confirm .code input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.MuiButton-root.MuiButton-text.orange {
  background: linear-gradient(90deg, #ffad65 -10.67%, #ff7636 121.34%);
  border: 0;
  color: #fff;
}

.MuiOutlinedInput-root.MuiInputBase-root {
  background-color: #fbfbfb;
}

.row.item.full .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}

.row.item.full {
  margin-bottom: 15px;
}

.action-buttons
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.withdraw {
  background: #fd6f6f;
  border-radius: 50px;
  color: #fff;
  padding: 7px 40px;
  text-transform: capitalize;
}

.withdraw-total-ammount {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #52b797;
  box-sizing: border-box;
  border-radius: 15px;
  background: rgba(82, 183, 151, 0.15);
  color: #52b797;
  padding: 20px;
}

.withdraw-total-ammount div:last-child {
  font-weight: 700;
}

.withdraw-total-ammount div {
  margin: 5px;
}

.drawler-copy.copied {
  background-color: #53b797;
  color: #fff;
}

.drawler-logo {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0 1px 0 1px #0000001a;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
}

.drawler-copy {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background: rgba(196, 196, 196, 0.25);
  border-radius: 15px;
  padding: 15px 20px;
  transition: all 0.5s;
  width: 80%;
}

.drawler-copy .brand-contact-copy svg {
  color: #52b797;
}

.drawler-copy.copied .brand-contact-copy svg {
  color: #fff;
}
/* 
.root-menu-active {
  overflow-x: hidden;
  position: relative;
} */

.main-container.active-drawler {
  transform: scale(0.9);
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.06));
  position: fixed;
  top: 0px;
}

.main-container.active {
  transform: scale(0.7);
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.06));
  position: absolute;
  top: 0px;
  right: -50%;
  width: 100%;
  z-index: 999;
  max-height: 65vh;
}
.main-container {
  transition: all 0.3s;
  transform: scale(1);
  position: relative;
  top: 0px;
  right: 0%;
  width: 100%;
  overflow: hidden;
}

a {
  color: unset;
  text-decoration: unset;
}

body.white {
  background: #fff;
}
body {
  background: #f6ffe5;
}

button.MuiButton-root.MuiButton-text.rounded {
  border-radius: 50px;
  padding: 10px 45px;
  font-size: 14px;
  text-transform: initial;
}

.margin-large {
  margin: 40px 0;
}

.MuiButtonBase-root.custom-select-container {
  background: rgb(196 196 196 / 11%);
  border: 1px solid #1c1c1c2b;
  box-sizing: border-box;
  border-radius: 15px;
  width: 100%;
  padding: 3px 10px;
  display: flex;
  justify-content: flex-start;
  color: #1c1c1c;
  font-weight: 300;
  align-content: center;
  align-items: center;
}

.MuiButtonBase-root.custom-select-container h3 {
  margin-left: 10px;
  font-weight: 300;
  color: #876f77;
}

.MuiButtonBase-root.custom-select-container svg {
  color: #52b797;
}

.MuiOutlinedInput-root.MuiInputBase-root {
  border-radius: 15px;
  background-color: #fbfbfb;
}

.light {
  font-weight: 300;
}
.center {
  text-align: center;
}
.margin {
  margin: 20px 0;
}
.txt14 {
  font-size: 14px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#root {
  position: relative;
}

@media screen and (min-width: 1000px) {
  body {
    width: 400px;
    margin: 0 auto;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    width: 400px;
    margin: 0px auto;
  }
  .main-container.active-drawler {
    position: fixed;
    top: 0px;
    max-width: 400px;
    margin: 0 auto;
    left: 0%;
  }
  .MuiDialog-container {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
