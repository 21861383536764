.code_confirm {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.code_confirm .code {
  width: 18%;
  padding: 0 10px;
}

.code_confirm .code input {
  font-size: 25px;
  text-align: center;
}

button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.Mui-disabled {
  opacity: 0.5;
}

h3.phone-check span {
  color: #169e73;
  font-weight: 400;
}

h4.resend-code {
  text-align: center;
  font-weight: 400;
  margin-top: 40px;
  color: #767676;
}

h4.resend-code span {
  color: #459e88;
}

h4.resend-code.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.timer {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  color: #f68441;
}
