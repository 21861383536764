.share-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.share-buttons button {
  margin: 10px;
}

.share-buttons button img {
  width: 30px;
  height: 30px;
}
