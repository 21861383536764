.add-card-details {
  display: flex;
  align-items: center;
}

.add-card-details .icon-card {
  margin-right: 15px;
  background-color: #000;
  padding: 5px 8px;
  border-radius: 10px;
}
