.logo.center {
  margin-left: 0;
  color: #01aa59;
  font-weight: bold;
}

.logo.burger {
  border: 1px solid #e8e8e8;
  color: #1c1c1c;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 0;
}

.home-top-text {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  align-content: space-between;
  padding: 0px 14px;
}

.home-top-text.center-demo {
    align-items: center;
    justify-content: center;
}

.home-top-text .orange {
  color: #ff7636;
}
