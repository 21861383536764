div#google-map > div {
  border-radius: 12px;
  box-shadow: 0 1px 3px 1px #00000036;
  transform: scale(1.09);
  margin-top: 19px;
}

div#google-map > div:after {
  content: "";
  background: linear-gradient(white, #ffffff);
  position: absolute;
  top: 0;
  width: 100%;
  height: 48px;
  box-shadow: 0 7px 7px 0 #fff;
  pointer-events: none;
}
