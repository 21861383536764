p.MuiTypography-root.MuiTypography-body1.color-red {
  color: red;
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.agenda-adv-data ol li::marker {
  font-size: 13px;
}
.agenda-adv-data ol {
  padding-left: 10px;
}

.agenda-adv-data {
  height: 69vh;
  overflow: auto;
  box-shadow: inset 0 0px 5px 0px #0003;
  padding: 20px;
  border-radius: 8px;
}

.agenda-adv-data.privacy-policy {
    height: unset;
    overflow: auto;
    box-shadow: unset;
}
