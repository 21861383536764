.user-info-img-container {
  display: flex;
  align-items: center;
}

.header-nav-items
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.material-icons.notranslate.MuiIcon-root {
  color: #f77636;
}

.MuiFormControl-root.service-picker
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
  padding: 10px 0;
}

.MuiFormControl-root.service-picker
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  background-color: transparent;
}

.MuiFormControl-root.service-picker fieldset.MuiOutlinedInput-notchedOutline {
  border: 0;
}

.user-info-img-container svg {
  font-size: 20px;
  margin-left: 15px;
  color: #a1af9d;
}

.header-menu .user-info {
  display: unset;
  margin: 0;
}

.header-menu {
  margin-top: 70px;
  padding-left: 20px;
  height: 80vh;
  position: relative;
}

.user-info-img {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 0px;
  background-position-y: 5px;
  border: 1px solid rgb(142 142 142 / 24%);
  border-radius: 50px;
  background-size: 54px;
  padding: 3px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cacaca;
  font-size: 15px;
  font-weight: 700;
}

.user-info h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-info h3 {
  font-weight: 100;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.header-nav-items {
  width: 63%;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.header-nav-items .header-link-title {
  margin-left: 15px;
  font-weight: 200;
}

.header-nav-items.bottom-exit {
  bottom: 0;
  margin: 0;
  margin-top: 70px;
}
