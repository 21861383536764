button#basic-button.lang-button {
  padding: 0;
  line-height: unset;
}

.header-bottom-info .info-left h3 {
  margin: 0 10px;
}

.info-left img {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 0 0px 5px #ffffff47;
  object-fit: cover;
  object-position: center;
  width: 70px;
  height: 70px;
}

.info-left.payments > img {
  background-color: unset;
  box-shadow: unset;
  object-fit: none;
}

span.MuiCircularProgress-root.reg-progress {
  color: #52b797;
  padding: 0 20px;
}

header {
  display: flex;
  align-content: flex-end;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.top-containers .logo.green {
  color: #01aa59;
  font-size: 25px;
  margin-top: 5px;
}

.header-bottom-info.open-box {
  padding: 15px;
}

.header-bottom-info.open-box .info-left.text {
  font-size: 16px;
  line-height: 25px;
}

.info-left.text {
  font-size: 12px;
  line-height: 19px;
  margin-left: 20px;
  color: #fff;
  display: flex;
  align-items: center;
}

.top-containers .black .icon-back svg {
  color: #000;
}

.black div {
  color: #000;
}

.top-containers .black {
  color: #000;
}

.top-containers .black .icon-back {
  border: 1.5px solid #000;
}
.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: -webkit-fill-available;
  background-size: cover;
}

img.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.select-service:active {
  transform: scale(0.98);
}

.header-bottom-data {
  z-index: 1;
  margin-bottom: 75px;
  font-size: 20px;
  font-weight: 800;
  font-family: monospace;
  color: #fff;
  transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 9999999999;
}

.top-containers {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  padding-top: 40px;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 999;
}

.top-containers div {
  color: #fff;
}

.language-select {
  background: linear-gradient(90deg, #ffad65 -10.67%, #ff7636 121.34%);
  margin: 0;
  width: 75px;
  padding: 4px 6px;
  font-size: 14px;
  border-radius: 20px 0px 0px 20px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  transition: all 0.05s;
}

.top-containers .logo {
  margin-left: 15px;
  font-size: 20px;
  font-weight: 800;
  font-family: monospace;
  margin-top: -19px;
  z-index: 2;
}

.go-back-icon {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.go-back-icon svg {
  font-size: 11px;
  padding-right: 1px;
}

.go-back-icon div.icon-back {
  border-radius: 5px;
  border: 1.5px solid #ffffff;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 3px;
}

/* .login-data-container::after {
  content: "";
  width: 100%;
  height: 50px;
  background: linear-gradient(132.08deg, #52b797 17.89%, #eeff87 120.86%);
  top: 0;
  position: absolute;
  left: 0;
  z-index: 1;
}
 */

.header-bottom-info {
  display: flex;
  color: #000;
  z-index: 999;
  width: 100%;
  justify-content: space-between;
  background: linear-gradient(109.72deg, #52b797 11.55%, #eeff87 135.98%);
  border-radius: 15px;
  padding: 25px 25px;
  margin: 15px 15px 35px 15px;
}

.header-bottom-info h2 {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  color: #fff;
}

.header-bottom-info h3 {
  font-size: 16px;
  margin: 0;
  margin-top: 15px;
  color: #fff;
}

.info-right.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.info-right.bottom button.MuiButton-root {
  background: #ffffff;
  /* Shadow */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  color: #000;
  padding: 3px 20px;
  text-transform: capitalize;
}

.info-left.list h3 {
  margin: 0;
  letter-spacing: 1px;
}

.info-left.list h5 {
  margin: 0;
  margin-top: 5px;
  font-weight: 400;
  color: #fff;
  font-size: 15px;
}

.info-left.list h5 span {
  font-weight: 700;
}

.info-left.list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
}
.settings {
  display: flex;
  align-items: center;
}

.settings .filter {
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
