.already-have {
  width: 100%;
  text-align: center;
}

.upload-logo.error div:first-child {
  color: red;
}

.already-have span {
  color: #53b797;
  margin-left: 5px;
}

.upload-logo-item {
  background-color: #000;
  padding: 13px 15px;
  border-radius: 0px 15px 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-logo div:first-child {
  color: #1c1c1ca8;
  border: 1px solid #c6c6c6;
  border-right: unset;
  box-sizing: border-box;
  border-radius: 15px 0px 0px 15px;
  display: flex;
  padding: 13px 25px;
  align-items: center;
  align-content: space-around;
  justify-content: space-between;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.upload-logo {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  position: relative;
}

.upload-logo input {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  width: 100%;
  height: -webkit-fill-available;
  opacity: 0;
}
