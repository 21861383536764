div .brands-list .top button.MuiButtonBase-root:last-child {
  margin-right: unset;
}
.ammount-avaiable {
  background: #384a45;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  margin-top: 18px;
  font-size: 16px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.brand-raiting {
  color: #000;
  font-size: 14px;
}

.brand-raiting-item {
  align-items: center;
  display: flex;
  font-size: 13px;
  color: #fdbe4d;
}

.brand-raiting-item svg {
  font-size: 13px;
}

.brand-raiting-title {
  font-weight: 400;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.brands-list .top .functionalButtons {
  display: flex;
  align-items: center;
}
