a.link {
  color: -webkit-link;
  margin-left: 5px;
  z-index: 999;
  font-size: 14px;
  text-decoration: underline;
}
.upload-logo img {
  border-radius: 50px;
}
