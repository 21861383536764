.brand-contact-details {
  margin-top: 10px;
}

.discounts-coupon.copied {
  border-color: #53b797;
  border-width: 4px;
}

.header-bottom-info.open-box.home {
  width: unset;
  margin: 0;
}

.info-left.text .count {
  font-size: 18px;
  font-weight: 800;
}

.info-left.text {
  font-weight: 400;
}

.discounts-coupon {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 25px 0;
  border: 2px dashed #c3c3c3;
  border-radius: 15px;
  transition: all 0.2s !important;
}

.discounts-coupon .discount-info .copy-coupon .coupon-code.copied {
  background-color: #52b797;
  color: #fff;
  border-radius: 3px;
}

.discounts-coupon .discount-separator {
  border: 1px dashed #c3c3c3;
  padding: 30px 0;
}

.discounts-coupon .discount-info h3,
h4 {
  margin: 0;
}

.discounts-coupon .discount-info .copy-coupon {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.dicount-icon {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 34px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 34%);
  border-radius: 50px;
  background-size: cover;
}

.discounts-coupon .discount-info h3 {
  font-weight: 700;
  font-size: 16px;
}

.discounts-coupon .discount-info h4 {
  font-weight: 100;
  font-size: 12px;
  margin-top: 6px;
}

.discounts-coupon .discount-info .copy-coupon .coupon-code {
  margin-right: 15px;
  position: relative;
  padding: 3px 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #00000040;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s;
}

.discounts-coupon .discount-info .info-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-bottom-info.open-box.home .info-left.text {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  line-height: 15px;
  margin-left: 7px;
}

.discounts-coupon .discount-info .copy-coupon svg {
  color: #52b797;
  font-size: 15px;
}

.discounts-coupon .discount-info .copy-coupon .coupon-code::after {
  content: "";
  position: absolute;
  height: fit-content;
  background-color: #52b797;
  width: 5px;
  left: 0;
  padding: 10px 0px;
  top: 0;
  transition: all 0.2s;
}

.discounts-coupon .discount-info .copy-coupon .coupon-code.copied::after {
  width: 100%;
  opacity: 0;
}
