.login-data-container {
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;
  position: relative;
  margin: 10px 0px;
  padding: 10px 15px;
}
.welcome-text {
  font-size: 13px;
}

section.main-data-container {
    z-index: 999999;
    position: relative;
}

.select-service {
  margin: 10px 0;
  background: #f6ffe5;
  border: 1px solid #dae8c1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 20px 20px;
  background-repeat: no-repeat;
  background-size: 30%;
  background-position-x: right;
  background-position-y: bottom;
}

.select-service-container h3 {
  font-size: 24px;
  width: 50%;
  margin: 0;
  color: #01aa59;
}

.select-service-container h4 {
  font-weight: 400;
  margin: 0;
  margin-top: 20px;
}

.select-service-container h4 span {
  color: #ff7636;
}

.welcome-text h3 {
  font-weight: 300;
}

.welcome-text h2 {
  font-weight: 500;
}
