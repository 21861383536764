.brands-list .top button.MuiButtonBase-root .brands-list {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px;
}

.brands-list .top {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.brands-list .top.right {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: center;
}

.brands-list .brand-icon-title {
  display: flex;
  align-items: center;
}

.brands-list .brand-icon-title img {
  background: #f9f9f9;
}

.brands-list .brand-icon-title .brand-icon {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  background-color: #eeeeee;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #00000026;
  font-weight: bolder;
}

.brands-list .brand-icon-title h3 {
  display: flex;
  color: #52b797;
  margin: 0;
  margin-left: 15px;
}

.brands-list .bottom {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 15px;
  margin-top: 20px;
}

.brands-list .bottom .bottom-container div {
  text-transform: capitalize;
}

.brands-list .bottom .bottom-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  color: #1c1c1c73;
}

.brands-list .top button.MuiButtonBase-root {
  border-radius: 50px;
  border: 1px solid #9b9b9b;
  width: 42px;
  margin-right: 10px;
}
