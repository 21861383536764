button.MuiButton-root.MuiButtonBase-root.green-button {
  background: #52b797;
  color: #fff;
  border-radius: 15px;
  padding: 11px;
  text-transform: initial;
}

button.MuiButton-root.MuiButtonBase-root.full {
  width: 100%;
}

.react-html5-camera-photo > .display-error,
.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  height: 258px;
}

video {
}

.react-html5-camera-photo video {
  border-radius: 15px;
}

.rounded {
  border-radius: 15px;
}
